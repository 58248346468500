import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'chat',
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: '',
		canActivate: [AuthGuard],
		children: [
			{
				path: 'deals/drafts',
				loadChildren: () =>
					import('./modules/deals-creation/deals-creation.module').then(m => m.DealsCreationModule),
			},
			{
				path: 'deals/verification',
				loadChildren: () =>
					import('./modules/deal-verification/deal-verification.module').then(m => m.DealVerificationModule),
			},
			{
				path: '',
				loadComponent: () => import('./modules/home/home.component').then(c => c.HomeComponent),
				children: [
					{
						path: 'profile',
						loadChildren: () =>
							import('./modules/profile/account/account.module').then(m => m.AccountModule),
					},
					{ path: 'chat', loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule) },
					{
						path: 'deals',
						loadChildren: () => import('./modules/deals/deals.module').then(m => m.DealsModule),
					},
					{
						path: 'articles',
						loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule),
					},
					{
						path: 'notifications',
						loadChildren: () =>
							import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
					},
					{
						path: 'documents',
						loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
					},
					{
						path: 'users',
						loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
					},
				],
			},
			{
				path: 'profile',
				children: [
					{
						path: 'registration',
						loadChildren: () =>
							import('./modules/profile/profile-registration/profile-registration.module').then(
								m => m.ProfileRegistrationModule,
							),
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
