import { NumberInput } from '@angular/cdk/coercion';
import pickBy from 'lodash.pickby';

const checkValueNotEmpty = (v: NumberInput) => v !== null && v !== undefined && v !== '';

export const removeAllEmpty = (obj: any | undefined): any => {
	if (!obj) return {};

	return pickBy(obj, (v: string | null) => checkValueNotEmpty(v));
};
