<div class="toast">
	<ng-container [ngSwitch]="toastPackage.toastType">
		<div class="toast__icon" *ngSwitchCase="'toast-error'">
			<t-icon icon="close"></t-icon>
		</div>
		<div class="toast__icon" *ngSwitchCase="'toast-success'">
			<t-icon icon="check"></t-icon>
		</div>
	</ng-container>
	<div class="toast__message">{{ message }}</div>
	<button class="toast__button">
		<t-icon icon="close"></t-icon>
	</button>
</div>
