import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { errorFromBack, recoverAccount } from '../../chat/utils/errorFromBack';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor(private toastr: ToastrService) {}

	error(err: HttpErrorResponse | string, backupError = 'Error'): void {
		const error = errorFromBack(err, backupError);
		if (error?.includes(recoverAccount)) return;

		this.toastr.error(error);
	}

	success(successMessage: string): void {
		this.toastr.success(successMessage);
	}
}
